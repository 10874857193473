import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, take, tap } from 'rxjs/operators';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { FigPlatformService } from 'src/app/core/services/figplatform.service';
import { ContractingTrainingQuickLink } from 'src/app/portal/components/carriers-training/models/carrier-training-quick-link.models';
import { ContractingQuickLinksService } from 'src/app/portal/components/carriers-training/services/carrier-training-quick-links.service';
import { weightedSearch } from 'src/app/shared/helpers/search.helpers';
import { FigPlatformTile } from 'src/app/shared/models/figplatform.models';
import { LifeInsuranceResources } from 'src/app/shared/models/life-insurance-resources.models';
import { miscellaneousSearchItems } from './constants/miscallenous-search.constant';
import { SearchResultModel } from './models/search-result.model';
import { LifeInsuranceResourcesApiService } from 'src/app/core/services/http/airtable-api/life-insurance-resources-api.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {
  public searchTermSubject = new Subject<string | string[]>();
  public searchResultSubject = new Subject<SearchResultModel[]>();
  public isSearchingSubject = new Subject<boolean>();
  private allResources: SearchResultModel[] = [];
  private readonly searchProperties = ['Tags', 'Name'];

  constructor(
    private figPlatformService: FigPlatformService,
    private lifeInsuranceResourcesApiService: LifeInsuranceResourcesApiService,
    private permissionService: PermissionService,
    private contractingQuickLinksService: ContractingQuickLinksService
  ) {
    this.getResources().subscribe(res => {
      this.allResources = [
        ...res.exploreTiles,
        ...res.lifeInsurance,
        ...res.misc,
        ...res.trainingQuickLinks
      ];
    });

    this.searchTermSubject
      .pipe(
        filter(searchTerm => !!searchTerm),
        tap(() => this.isSearchingSubject.next(true)),
        debounceTime(350),
      )
      .subscribe(searchTerm => {
        this.searchResultSubject.next(weightedSearch(this.allResources, searchTerm, this.searchProperties));
        this.isSearchingSubject.next(false);
      });
  }

  getResources(): Observable<{
    exploreTiles: SearchResultModel[];
    lifeInsurance: SearchResultModel[];
    trainingQuickLinks: SearchResultModel[];
    misc: SearchResultModel[];
  }> {
    return forkJoin({
      exploreTiles: this.figPlatformService.getUserPermittedTiles()
        .pipe(
          catchError(() => of([])),
          map((tiles: FigPlatformTile[]) => this.mapExploreEntries(tiles))
        ),
      lifeInsurance: this.lifeInsuranceResourcesApiService.getUserPermittedTiles()
        .pipe(
          catchError(() => of([])),
          map((tiles: LifeInsuranceResources[]) => this.mapLifeEntries(tiles))
        ),
      trainingQuickLinks: of(this.contractingQuickLinksService.getPermittedQuickLinks())
        .pipe(
          catchError(() => of([])),
          map((links: ContractingTrainingQuickLink[]) => this.mapCarrierTrainingQuickLinks(links))
        ),
      misc: of(miscellaneousSearchItems.filter(item => !item.Permissions || this.permissionService.hasAllPermissions(item.Permissions)) as SearchResultModel[]),
    })
      .pipe(
        take(1)
      );
  }

  private mapLifeEntries(lifeTiles?: LifeInsuranceResources[]): SearchResultModel[] {
    return lifeTiles?.map(tile => {
      return {
        Name: tile.Fields.Name,
        SubTitle: tile.Fields.SubCategoryName,
        Icon: 'local_library',
        Url: tile.Fields.ExternalUrl,
        Tags: tile.Fields.Tags
      };
    }) || [];
  }

  private mapCarrierTrainingQuickLinks(quickLinks: ContractingTrainingQuickLink[]): SearchResultModel[] {
    return quickLinks.map(link => {
      return {
        Name: link.Name,
        SubTitle: link.Description || 'Contracting quick links',
        Icon: 'history_edu',
        Action: link.Action,
        Tags: link.Tags,
        Url: link.Link,
      };
    });
  }

  private mapExploreEntries(exploreTiles: FigPlatformTile[]): SearchResultModel[] {
    return exploreTiles.map(tile => {
      return {
        Name: tile.Fields.Title,
        SubTitle: tile.Fields.Subtitle,
        Tags: tile.Fields.Tags,
        Icon: 'map',
        Route: tile.Fields.AngularRoute,
        Url: tile.Fields.ExternalUrl
      };
    });
  }
}
