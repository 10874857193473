<div class="relative flex p-10 sm:w-384">
  <i class="absolute mr-10 transform -translate-y-1/2 left-15 material-icons text-blue top-1/2">search</i>
  <input class="w-full border-2 input is-lg border-blue-light is-inverted pl-36 bg-gray-lightest" type="text" name="search"
    aria-label="Search Portal Resources" placeholder="Search Portal Resources" (keyup)="filterSearch()"
    [matAutocomplete]="searchAuto" [(ngModel)]="searchText" />
    <mat-autocomplete #searchAuto="matAutocomplete"
      class="flex flex-col p-10 bg-white gap-y-5 select min-h-256 is-inverted md-no-underline wrap-shadow-none"
      [displayWith]="displayFn" (optionSelected)="logSearchTracking($event.option.value)" (closed)="searchEntry === searchText ? logSearchTracking(null) : ''">
      @for (r of searchResultsList; track r) {
        <mat-option class="flex w-full overflow-visible custom-mat-option" [value]="r">
          @if (r.Url) {
            <a [href]="r.Url" target="_blank">
              <app-search-result [searchResult]="r"></app-search-result>
            </a>
          }
          @if (r.Route) {
            <a routerLink="{{r.Route}}">
              <app-search-result [searchResult]="r"></app-search-result>
            </a>
          }
          @if (r.Action) {
            <a (click)="r.Action()">
              <app-search-result [searchResult]="r"></app-search-result>
            </a>
          }
        </mat-option>
      }
      @if ((globalSearchService.isSearchingSubject | async) === false && searchText && !searchResultsList.length) {
        <mat-option disabled
          class="flex w-full h-full p-10 overflow-visible rounded custom-mat-option">
          <span>No results found</span>
        </mat-option>
      }
    </mat-autocomplete>
  </div>