export interface EAppProduct {
  CarrierName: string;
  CarrierCode: string | null;
  ProductName: string;
  ProductId: string | null;
  ProductCusip: string | null;
  ProductTypeId: string | null;
  States: EAppState[];
  EAppVendor: string;
}

export interface EAppState {
  JurisdictionId: number;
  StateAbbrev: string;
}

export interface EAppCarrier {
  CarrierName: string;
  CarrierCode: string | null;
}


export interface ExternalVendorProduct {
  Product: string;
  Business: string;
  CarrierCode?: string | null;
  CarrierID: number;
  LineOfBusinessID: number;
  // If vendor === firelight this will be the cusip. If vendor === iPipeline this will be the productId
  ProductCusip?: string | null;
  ExternalVendorProductTypeID?: number | null;
  ExternalVendor?: string;
  ExternalVendorID: number;
  States: ExternalVendorProductState[];
  LineOfBusinessCategoryId: number;
}

export interface ExternalVendorProductV2 {
  Product: string;
  Business: string;
  CarrierCode?: string | null;
  CarrierID: number;
  LineOfBusinessID: number;
  // If vendor === firelight this will be the cusip. If vendor === iPipeline this will be the productId
  ProductCusip?: string | null;
  ExternalVendorProductTypeID?: number | null;
  ExternalVendor?: string;
  ExternalVendorID: number;
  NotAvailableStates: ExternalVendorProductState[];
  LineOfBusinessCategoryId: number;
}

export interface ExternalVendorProductState {
  JurisdictionId: number;
  StateAbbrev: string;
}

export interface ExternalVendorCarrier {
  Business: string;
  CarrierCode?: string | null;
  CarrierID: number;
}

export enum EAppErrorType {
  StartApplicationError = 'Unable to start application. Please make sure all fields are completed and try again.',
  ClientInfoMissingError = 'Please enter client first and last name before starting your application.',
  ProductNotAvailableForEappSubmissionError = 'Sorry, this product is not available for E-App submission. Please begin a paper application instead.',
  // TODO: Uncomment this section of code when the Traning bug is fixed with our carriers
  // TrainingUnavailableError = 'This product is not yet supported on our training platform, please verify all required training has been completed.',
  UnableToRunCanSellCheckError = 'Sorry, there was an issue checking your status at this time. You can still choose to continue with your application.',
  UnableToRetrieveProductsError = 'Sorry, there was an error retrieving products. Please try again later.',
}