<section class="flex flex-col w-full h-full overflow-hidden">

  <header class="md:px-30 items-center border-b border-gray-lighter flex-none">
    <div class="flex flex-col justify-between gap-y-5">
      <span class="font-bold text-xl">Paper Application Upload History</span>
    </div>
    <button mat-button
      matTooltip="Close Dialog"
      type="button"
      mat-dialog-close
      class="ml-auto icon-button is-primary is-inverted"><i class="material-icons">close</i></button>
    </header>
    <section class="flex gap-y-10 flex-col flex-grow p-20 md:p-30 overflow-auto">
      @if (!isLoading) {
        @for (upload of paperUploads; track upload) {
          <div class="p-10 bg-gray-lightest flex rounded">
            <div class="flex flex-col gap-y-5 mr-20">
              <span class="text-sm text-gray" [innerText]="upload.CreatedOn | date: 'M/d/YYY H:mm a'"></span>
              <span class="text-sm" [innerText]="upload.Note"></span>
            </div>
            <div class="ml-auto flex text-right flex-col gap-y-5">
              @for (file of upload.Files; track file) {
                <span class="text-gray text-xs whitespace-nowrap" [innerText]="file.FileName"
                  >
                </span>
              }
            </div>
          </div>
        }@empty {
          <div class="flex justify-center items-center w-full h-full p-20">
            <span class="bg-gray-lightest mx-auto border-1 rounded-sm border-gray px-20 py-10">No paper applications found.</span>
          </div>
        }
      }@else {
        <div class="relative w-full h-full p-20 loader-wrap">
          <div class="loader-sm is-blue"></div>
        </div>
      }
    </section>
  </section>