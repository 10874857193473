<section class="flex w-full flex-grow">
  @if (searchResult?.Icon) {
    <span class="material-icons mi-18 mr-10 py-10 align-middle text-gray">{{searchResult?.Icon}}</span>
  } @else if (searchResult?.FigIcon) {
    <span class="fig-icons fig-18 mr-10 py-10 align-middle text-gray">{{searchResult?.FigIcon}}</span>
  }
  <span class="flex flex-col justify-center flex-grow">
    <span class="font-bold">{{searchResult?.Name}}</span>
    <span class="text-sm text-gray" [innerText]="searchResult?.SubTitle"></span>
  </span>
</section>
