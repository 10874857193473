<section class="flex flex-col w-full h-full overflow-hidden">
  <section class="flex flex-col flex-grow overflow-auto text-center p-30 gap-y-20">
    <img src="./assets/images/ibexis-logo@2x.png" alt="Ibexis Logo" class="w-auto mx-auto h-72">
    <span class="px-10 py-5 mx-auto text-sm text-white rounded-full bg-gray-dark">AM Best Carrier Rating: A-</span>
    <div class="flex flex-col text-lg gap-y-20">
      <p>Introducing the new MYGA Plus&trade; from Ibexis&trade;, an Individual Single Premium Deferred Fixed Annuity with Index-Linked interest options, in 3, 5 or 7 Year terms. Simple interest.</p>
      <p class="text-red">This product is not yet illustrated on Annuity Rate Watch&trade;.</p>
    </div>
    <div class="flex flex-col gap-y-20">
      <p class="font-bold">The MYGA Plus&trade; offers 2 ways to earn interest:</p>
      <div class="grid grid-cols-2 gap-x-40">
        <div class="flex flex-col gap-y-10">
          <span class="font-bold label">Fixed Option</span>
          <table class="text-sm">
            <tr class="font-bold text-blue">
              <td class="p-10 text-white border w-165 bg-blue border-gray-light">GOP</td>
              <td class="p-10 border bg-blue-lightest border-gray-light">3 Years</td>
              <td class="p-10 border bg-blue-lightest border-gray-light">5 Years</td>
              <td class="p-10 border bg-blue-lightest border-gray-light">7 Years</td>
            </tr>
            <tr>
              <td class="p-10 border border-gray-light">Guaranteed rate for low band GOP (10,000 - 99,999)</td>
              <td class="p-10 border border-gray-light">5.05%</td>
              <td class="p-10 border border-gray-light">5.55%</td>
              <td class="p-10 border border-gray-light">5.30%</td>
            </tr>
            <tr>
              <td class="p-10 border border-gray-light">Guaranteed rate for high band GOP (100,000 - 1,500,000)</td>
              <td class="p-10 border border-gray-light">5.25%</td>
              <td class="p-10 border border-gray-light">5.80%</td>
              <td class="p-10 border border-gray-light">5.55%</td>
            </tr>
          </table>
        </div>
        <div class="flex flex-col gap-y-10">
          <span class="font-bold label">Index-Linked Option</span>
          <table class="text-sm">
            <tr class="font-bold text-blue">
              <td class="p-10 text-white border w-165 bg-blue border-gray-light">GOP</td>
              <td class="p-10 border bg-blue-lightest border-gray-light">3 Years</td>
              <td class="p-10 border bg-blue-lightest border-gray-light">5 Years</td>
              <td class="p-10 border bg-blue-lightest border-gray-light">7 Years</td>
            </tr>
            <tr>
              <td class="p-10 border border-gray-light">Guaranteed rate for low band GOP (10,000 - 99,999)</td>
              <td class="p-10 border border-gray-light">7.20%</td>
              <td class="p-10 border border-gray-light">7.30%</td>
              <td class="p-10 border border-gray-light">7.00%</td>
            </tr>
            <tr>
              <td class="p-10 border border-gray-light">Guaranteed rate for high band GOP (100,000 - 1,500,000)</td>
              <td class="p-10 border border-gray-light">7.40%</td>
              <td class="p-10 border border-gray-light">7.50%</td>
              <td class="p-10 border border-gray-light">7.20%</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="flex flex-col text-lg">
      <p>Need to get contracted? <a class="font-bold underline cursor-pointer text-blue-dark hover:text-blue-darker" (click)="goToSureLc()"> Click here to go to SureLC&trade;.</a></p>
      <p>Please contact your sales team today to learn more!</p>
    </div>
    <p class="text-sm text-gray">Available in AK, AR, AZ, CO, CT, DC, DE, GA, HI, IA, ID, IL, IN, KS, KY, LA, MA, MD, ME, MI, MN, MO, MS, MT, NC, ND, NE, NM, NV, OH, OK, OR, PA, RI, SC, SD, TX, UT, VA, WA, WV, and WY</p>
  </section>
  <section class="flex flex-col flex-none text-center border-t border-gray-lighter p-30 gap-y-20">
    <p>Or continue to Annuity Rate Watch&trade; below:</p>
    <button class="button is-primary is-lg" mat-button (click)="goToArw()">Launch Annuity Rate Watch&trade;</button>
  </section>
</section>