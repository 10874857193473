<section class="flex flex-col w-full">
  <header class="flex items-center justify-between flex-none p-10 border-b border-solid md:p-20 border-gray-lighter">
    <h2 mat-dialog-title class="font-bold text-xl mb-0">Welcome to the New Agent Portal!</h2>
    <button class="icon-button is-primary is-inverted" tabindex="-1" aria-label="Close" [mat-dialog-close] type="button">
      <i class="material-icons">close</i>
    </button>
  </header>
  <mat-dialog-content class="flex flex-col  w-full overflow-auto" style="min-height:80rem;" @fadeIn>
    <iframe height="700" width="1500" class="flex-grow items-center justify-center overflow-visible" [src]="iframeUrl | safeUrl"></iframe>
  </mat-dialog-content>
</section>