import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SsoApiService } from 'src/app/core/services/http/sso-api.service';
import { SsoService } from 'src/app/core/services/sso.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { SureLcSsoUser } from 'src/app/shared/models/sso.models';
import { User } from 'src/app/shared/models/user.models';
import { UserGroup } from 'src/app/shared/models/usergroups.models';

@Component({
  selector: 'app-ibexus-myga-preview',
  templateUrl: './ibexus-myga-preview.component.html',
  styles: [
  ]
})
export class IbexusMygaPreviewComponent implements OnInit, AfterViewInit {
  user?: User;
  userGroup?: UserGroup | null;
  onBehalfOfUserGroup?: UserGroup | null;
  sureLcUser?: SureLcSsoUser;

  constructor(
    private ssoApiService: SsoApiService,
    private sessionStore: SessionStoreService,
    private ssoService: SsoService,
    private dialogRef: MatDialogRef<IbexusMygaPreviewComponent>
  ) { }


  ngOnInit(): void {
    this.user = this.sessionStore.User;
    this.userGroup = this.sessionStore.UserGroup;
    this.onBehalfOfUserGroup = this.sessionStore.OnBehalfOfUserGroup;

    this.ssoApiService.getSureLcUser()
      .subscribe({
        next: res => {
          this.sureLcUser = res;
        }
      });
  }

  ngAfterViewInit(): void {
    this.dialogRef.updateSize('84rem');
  }

  goToSureLc(): void {
    if (!this.sureLcUser) return;
    this.userGroup = this.onBehalfOfUserGroup || this.userGroup;

    if (this.userGroup && this.userGroup.AffiliationLink) {
      window.open(this.userGroup.AffiliationLink);
    } else {
      this.ssoService.sureLcSso(this.sureLcUser);
    }
  }

  goToArw(): void {
    this.ssoService.annuityRateWatchSso();
  }
}
