import { SearchResultModel } from '../models/search-result.model';

export const miscellaneousSearchItems:MiscellaneousSearchItem[] = [
  {
    Name: 'NIPR',
    SubTitle: 'National Insurance Producer Registry',
    Icon: 'map',
    Url: 'https://nipr.com/',
    Tags: ['licensed', 'licensing'],
  }
];


export interface MiscellaneousSearchItem extends SearchResultModel {
  Permissions?: string
}