import { Component } from '@angular/core';

@Component({
  selector: 'app-stage-history-timeline',
  templateUrl: './stage-history-timeline.component.html',
  styles: [
  ]
})
export class StageHistoryTimelineComponent {

  constructor() { }
}
